import styled from "styled-components";
import { Font, Color, breakpoints } from "@/shared/Constants";

export const Content = styled.div`
  position: relative;
  min-width: 240px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.colorText ? props.colorText : "white")};
  ${Font.sansRegular}
  gap: 1rem;
  padding: 2rem 0;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  overflow: hidden;

  ${breakpoints.phone} {
    height: 100px;
    padding: 1rem 0.2rem;
  }

  .image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      min-height: 120px;
    }
  }

  .timer,
  .text-countdown {
    z-index: 1;
    font-size: 14px;
  }

  .containerTimer {
    background-color: ${(props) =>
      props.colorBack ? props.colorBack : "rgba(85, 85, 85, 0.6)"};
    display: grid;
    place-items: center;
    border-radius: 16px;
    padding: 0 2rem;
    text-transform: uppercase;
    height: 120px;
    width: 100%;

    ${breakpoints.phone} {
      height: 100px;
      padding: 0 0.85rem;
    }

    .text-countdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${(props) => (props.colorText ? props.colorText : "white")};

      h3 {
        ${Font.clarinVarSub2ExtraBold}
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 1.2px;
        margin: 4px 0 12px;
        ${breakpoints.phone} {
          font-size: 14px;
        }
      }
      p {
        ${Font.sansSemiBold}
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 17px;
        margin: 4px 0 12px;
        ${breakpoints.phone} {
          font-size: 11px;
        }
      }
    }

    .timerContent {
      display: flex;
      flex-direction: row;
      column-gap: 8px;

      ${breakpoints.phone} {
        margin-left: 0px;
      }
      p {
        display: none;
        ${breakpoints.phone} {
          ${Font.sansRegular}
          font-size: 9px;
          letter-spacing: 1px;
          line-height: 17px;
          margin: 4px 0 0px;
          z-index: 0;
        }
      }
    }

    &.hasDouble {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 30px;

      ${breakpoints.phone} {
        display: flex;
        flex-direction: column;
        padding-top: 0px;
      }

      .text-countdown {
        align-items: end;
        margin-right: 30px;
        margin-top: -25px;
        ${breakpoints.phone} {
          margin: 12px 0 5px;
        }
        h3 {
          margin-bottom: 0;
          ${breakpoints.phone} {
            margin-left: 16px;
            margin-top: -10px;
          }
        }
        p {
          ${breakpoints.phone} {
            display: none;
          }
        }
      }
      .timerContent {
        margin-left: 0px;
        ${breakpoints.phone} {
          margin-top: 22px;
          margin-left: 0px;
        }
        p {
          display: none;
          ${breakpoints.phone} {
            display: block;
            margin-top: 0px;
          }
        }
      }
    }
  }

  .timer {
    margin-top: -18px;
    .contTextNum {
      display: grid;
      grid-template-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
      gap: 0.45rem;
      text-transform: uppercase;

      ${breakpoints.phone} {
        gap: 0.2rem;
      }
      .containerBoxNumber {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 10px;

        .boxNumber {
          color: ${(props) =>
            props.colorNumber ? props.colorNumber : "rgb(85, 85, 85)"};
          background-color: ${(props) =>
            props.colorBackNumber ? props.colorBackNumber : "white"};
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          width: 36px;
          height: 50px;
          padding-left: 3px;
          ${Font.clarinVarSub2Bold};
          font-size: 29px;
          letter-spacing: 3px;

          ${breakpoints.phone} {
            width: 25px;
            height: 34px;
            font-size: 20px;
          }
        }
      }
      .containerBoxDate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .timeCaption {
          ${Font.sansBold};
          font-size: 10px;
          line-height: 14px;
          margin-top: 12px;
          ${breakpoints.phone} {
            font-size: 8px;
            margin-top: 8px;
          }
        }
      }
      .boxPoint {
        display: grid;
        place-content: center;
        margin-bottom: auto;
        margin-top: 14px;
        font-size: 24px;

        ${breakpoints.phone} {
          margin-top: 8px;
        }
      }
    }
  }
`;
