import styled, { css } from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const DialogContent = styled.dialog`
  //position: absolute;
  display: none;
  //position: fixed;
  width: 80%;
  max-width: 496px;
  padding: 30px;
  background-color: ${Color.white};
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  ${breakpoints.darkMode} {
    background-color: ${ColorDark.darkGrey};
  }
  .btn-close {
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    div svg {
      fill: ${Color.black000};
      ${breakpoints.darkMode} {
        fill: ${ColorDark.textLight};
      }
    }
    :focus-visible {
      outline: none;
    }
    :hover {
      opacity: 0.7;
    }
  }
  .popup-content-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .icon-paywall {
      div {
        width: 25px;
        height: 25px;
        background: ${Color.yellow2};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 15px;
      }
    }
  }
  .popup-content-text {
    ${Font.sansRegular}
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 20px;
    ${breakpoints.darkMode} {
      color: ${ColorDark.textLight};
    }
  }
  .bold {
    ${Font.sansBold}
  }
  .remember {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    ${breakpoints.phone} {
      width: 100%;
    }
  }
  .popup-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .popup-button-login {
      width: 208px;
      height: 46px;
      border: 1px solid ${Color.black000};
      border-radius: 50px;
      background-color: transparent;
      ${Font.sansRegular}
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      ${breakpoints.darkMode} {
        color: ${ColorDark.textLight};
        border: 1px solid ${ColorDark.textLight};
      }
      div svg svg {
        fill: ${Color.black000};
        ${breakpoints.darkMode} {
          fill: ${ColorDark.textLight};
        }
      }
      :hover {
        opacity: 0.7;
      }
    }
    .popup-buttons-ignore {
      ${Font.sansRegular}
      font-size: 16px;
      border: none;
      background: none;
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      ${breakpoints.darkMode} {
        color: ${ColorDark.textLight};
      }
      :hover {
        opacity: 0.7;
      }
    }
  }
`;

export const DialogContentOverlay = styled.div`
  width: 100%;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 30%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;


const CicleStyle = css`
  content: "";
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  border: 3px solid ${Color.white};
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  ${breakpoints.phone} {
    border: 1px solid ${Color.white};
  }
  ${breakpoints.darkMode} {
    [data-theme='dark'] & {
      border-color:${ColorDark.darkGrey};
    }
  }
`;

export const Avatar = styled.picture`  
  position: relative;
  margin: auto;
  margin-bottom: 20px;
  margin-left: 50%;
  display: flex;
  align-self: center;
  justify-content: center;
  border: 8px solid ${Color.yellow2};
  border-radius: 50%;
  width: 71px;
  height: 71px;
  &::before {
    ${CicleStyle}
  } 
  img{
    width: 100%;
    height: auto;
    border-radius: 100%;
  }
  ${breakpoints.phone} {
    border: 4px solid ${Color.yellow2};
  }
  .avatar-iconStar{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 54px;
    background-color: ${Color.yellow2};
    border-radius: 30px 0 0 30px;
    z-index: -1;
    top: 50%;
    right: calc(50% - 18px);
    transform: translate(-50%, -50%);
  }
`;