export const breakpoints = {
  desktop: "@media (min-width: 1360px) ", /*no*/
  tablet: "@media (min-width: 1025px) and (max-width: 1359px)",/*no*/
  phone: "@media (max-width: 1024px)",
  phoneTablet: "@media (max-width:1255px)",/*no*/
  mobileFirst: "@media (min-width: 975px)",
  smallDesktop: "@media (min-width: 1201px) and (max-width: 1367px)",/*prueba nuevo para reemplazar tablet*/
  BigDesktop: "@media (min-width: 1367px) ", /*prueba nuevo para reemplazar desktop*/
  darkMode: "@media (max-width: 1024px)",
};




