import React, { useState, useEffect } from "react";
import { SubMenu, SubMenuChildren, Input, OverlayMenuOpen } from "./styles";
import MenuChildren from "./MenuChildren";
import ContentIcon from "@/shared/ContentIcon";
import { SubscribeButton } from "@/shared/SubscribeButton";

import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { getCookie } from "helpers";
import ThemeButton from "@/shared/ThemeButton";
import { Content } from "@/shared/Countdown/style";

const MenuLateral = ({ menulateral, suscriptionPrice, isHome }) => {
  const [scroll, setScroll] = useState(false);
  const [userSucription, setUserSucription] = useState(false);
  const findTooltip = menulateral?.find(
    (item) => item?.extras?.tooltip == true
  );

  const toogleOpen = (id) => {
    if (document.getElementById(id).hasAttribute("open")) {
      document.getElementById(id).removeAttribute("open");
    } else {
      document.getElementById(id).setAttribute("open", "");
    }
  };
  useEffect(() => {
    if (getCookie("statusSus") == 1) {
      setUserSucription(true);
    }
    window.addEventListener("scroll", () => {
      if (window.innerWidth > 1024) {
        setScroll(window.scrollY > 416);
      } else {
        setScroll(window.scrollY > 36);
      }
    });

    document
      .getElementById("side-menu-toggle")
      .addEventListener("click", function (e) {
        let hasBannerTop = document.querySelector('.bannerTopHeader');

        if (!hasBannerTop) {
          document.getElementById("side-menu").classList.add("noBannerTop");
        }
        if (
          document.getElementById("side-menu") &&
          !document.getElementById("side-menu").contains(e.target) &&
          !document.getElementById("side-menu-toggle").contains(e.target)
        ) {
          document.getElementById("side-menu-toggle").checked = false;
        }
        handleMenu();
      });

    document
      .querySelector(".overlay-menu-open")
      .addEventListener("click", function (e) {
        document.getElementById("side-menu-toggle").checked = false;
        handleMenu();
      });

    document
      .getElementById("btn-close")
      .addEventListener("click", function (e) {
        document.getElementById("side-menu-toggle").checked = false;
        handleMenu();
      });
  }, []);

  /* CALCULA EL ESPACIO DEL SCROLL POR DEFAULT DE LA WEB Y EVITA MOVIMIENTO HORIZONTAL AL ABRIR EL MENU LATERAL */
  const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
  };

  const handleMenu = () => {
    const htmlElement = document.documentElement; // Se refiere al elemento raíz <html>
    const scrollbarWidth = getScrollbarWidth();

    if (document.getElementById("side-menu-toggle").checked == false) {
      // Al cerrar el menú lateral
      document.querySelector(".overlay-menu-open").style.display = "none"; // Oculta el overlay
      htmlElement.classList.remove("part-hidden"); // Remueve todas las clases usadas
      htmlElement.style.paddingRight = "0px"; // Restaura el padding a su estado original
      document.getElementById("headerId")?.classList.remove("header-hidden"); // Restaura el encabezado si corresponde
    } else {
      // Al abrir el menú lateral
      htmlElement.classList.add("part-hidden"); // Deshabilita el desplazamiento
      htmlElement.style.paddingRight = `${scrollbarWidth}px`; // Compensa la barra de desplazamiento oculta
      document.querySelector(".overlay-menu-open").style.display = "block"; // Muestra el overlay
      document.getElementById("headerId")?.classList.add("header-hidden"); // Oculta el encabezado si corresponde
    }
  };

  return (
    <>
      <OverlayMenuOpen className="overlay-menu-open"></OverlayMenuOpen>
      <Input
        aria-label="Side Menu"
        id="side-menu-toggle"
        type="checkbox"
        className={`side-menu ${isHome && "home"} ${scroll ? "sticky" : "no-sticky"
          }`}
        readOnly
      />

      <label
        className="side-menu-label"
        id="checkbox-menu"
        htmlFor="side-menu-toggle"
      >
        {findTooltip && <span className="tooltip-alert" />}
        <ContentIcon
          fill="#555"
          width="21px"
          height="22px"
          nameIcon="bars-solid"
        />
      </label>
      <SubMenu
        className={` ${scroll ? "sticky" : "no-sticky"} ${isHome && "home"}`}
        itemScope="navigation"
        itemType="https://schema.org/SiteNavigationElement"
        id="side-menu"
      >
        <li className="submenu-head">
          <button id="btn-close" className="close" aria-label="Close Button" />
          <ThemeButton />
        </li>
        {!userSucription && (
          <li className="submenu-head_btn">
            <SubscribeButton
              title="Suscribite"
              type="btLateral"
              suscriptionPrice={suscriptionPrice}
              titleGA4="Sidebar"
            />
          </li>
        )}

        <li style={{ display: "none" }} itemProp="name">
          Secciones
        </li>
        {menulateral.length
          ? menulateral.map((data) => {
            const dataClassName =
              data.items.length > 0 ? "item sidebar-dropdown" : "item";
            let claringrillaClassName =
              data?.slug == "/claringrilla" ? "claringrillaTooltip " : " ";
            const tooltipDirection = data?.extras?.tooltipDirection ? "tooltip-direction-" + data?.extras?.tooltipDirection : "";
            const itemIcon = data?.extras?.itemIcon;
            return (
              <li
                key={data._id}
                className={claringrillaClassName + dataClassName}
              >
{/* LINKS PRINCIPALES */}
                <span itemProp="name" className="name">
                  {data.slug ? (
                    <a
                      itemProp="url"
                      target={data.type}
                      className="linkFirst"
                      href={data.slug}
                      onClick={() =>
                        handleClick("ui_interaction", "sidebar", data.name)
                      }
                    >
                      {itemIcon && <ContentIcon width="30" height="30" nameIcon={itemIcon} />}
                      {data.name}

                      {data?.extras?.tooltip == true && (
                        <span className={`tooltip ${tooltipDirection}`}>
                          {data?.extras?.tooltipText}
                        </span>
                      )}

                      
                    </a>
                    
                  ) : (
                    <span
                      itemProp="url"
                      className="linkFirst"
                      onClick={() => {
                        toogleOpen(data.name);
                      }}
                    >
                      {data.name}
                    </span>
                  )}
                    
                </span>

{/* LINKS SUBMENU DESPLEGABLE*/}
                {data.items.length > 0 ? (
                  <>
                    <details id={data.name}>
                      <summary>
                        <ContentIcon
                          fill="#000"
                          width="10px"
                          height="16px"
                          nameIcon="chevronUp"
                        />
                      </summary>
                      <SubMenuChildren>
                        <MenuChildren items={data.items} />
                      </SubMenuChildren>
                    </details>
                  </>
                ) : null}
              </li>
            );
          })
          : null}
      </SubMenu>
    </>
  );
};

export default MenuLateral;
