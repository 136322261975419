import { handleClick } from "../Analitics/GoogleAnalytics4";
import React, { useEffect, useState } from "react";
import ContentIcon from "@/shared/ContentIcon";
import { getCookie, setCookie } from "helpers";
import { TooltipContainer } from "./style"; 

export const TooltipInfo = ({
  title = "",          // Texto del tooltip
  alt = "",            // Texto relacionado a la imagen
  img = null,          // Imagen opcional
  imgWidth = "40",     // Ancho por default
  imgHeight = "50",    // Alto por default
  position = "bottom", // Posicion por default, puede ser setada: top, left, right
  customClass = "",    // Puede colocar una clase personalizable desde style.js del componente donde se reutilice el tooltopInfo
  onProcessGA = false, // Google analitics se envia como true cuando sea necesario para medir metricas ui_interaccion
  tooltipGASection,    // Variables para contabilizar metricas de usuarios
  tooltipGAName,       // Variables para contabilizar metricas de usuarios
}) => {

  // por defecto no se muestra el tooltip
  const [classTooltipInfo, setClassTooltipInfo] = useState("hide");

  const closeTooltip = (event) => {
    event.preventDefault();

    if (onProcessGA && !!tooltipGASection && !!tooltipGAName) handleClick('ui_interaction', tooltipGASection, tooltipGAName);

    //se oculta el tooltip
    setClassTooltipInfo("hide");

    //cookie tooltip_info
    setCookie("tooltip_info", "hide", 1);
  };

  useEffect(() => {
    //get cookie tooltip_info
    //si es distinta de hide, se muestra el tooltip
    getCookie("tooltip_info") !== "hide" && setClassTooltipInfo("");
  }, []);

  return (
    <TooltipContainer 
      className={`${classTooltipInfo} ${position} ${customClass}`} 
      role="tooltip"
    >
      {/* Mostrar imagen solo si se proporciona */}
      {img && (
        <figure className="tooltipInfo-icon">
          <img
            loading="lazy"
            src={img}
            alt={alt}
            style={{
              width: `${imgWidth || 40}px`,
              height: `${imgHeight || 50}px`,
              objectFit: "contain",
            }}
          />
        </figure>
      )}
      <div className="tooltipInfo-button-text">
        <span>{title}</span>
      </div>
      <button
        className="tooltipInfo-button-close"
        aria-label="cerrar"
        onClick={closeTooltip}
      >
        <ContentIcon fill="#fff" nameIcon="closeIco" />
      </button>
    </TooltipContainer>
  );
};