import { Color, Font, breakpoints } from "@/shared/Constants";
import styled, { css } from "styled-components";

export const ArrowTooltipCss = css`
  content: "";
  position: absolute;
  display: flex;
  width: 14px;
  height: 14px;
  background-color: inherit; 
  transform: translateX(-50%) rotate(45deg); 
  transform-origin: center;
  z-index: -1;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  width: 189px !important;
  height: 37px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  background-color: ${Color.black000};
  border-radius: 4px;
  padding: 0.75rem;
  z-index: 3;
  &:hover {
    box-shadow: 0 1px 10px #bab3d5;
  }
  ${breakpoints.phone} {
    width: 154px;
  }

  &.top {
    bottom: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    &::after {
      ${ArrowTooltipCss};
      border-radius: 20px 0 4px 0;
      bottom: -6px;
      left: 50%;
    }
  }

  &.right {
    top: 50%;
    left: calc(100% + 6px);
    transform: translateY(-50%);
    margin-left: 10px;
    &::after {
      ${ArrowTooltipCss};
      border-radius: 0 20px 0 4px;
      right: calc(100% + -14px);
    }
  }

  &.bottom {
    top: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    &::after {
      ${ArrowTooltipCss};
      border-radius: 4px 0 20px 0;
      bottom: 30px;
      left: 50%;
    }
  }

  &.left {
    top: 50%;
    right: calc(100% + 6px);
    transform: translateY(-50%);
    margin-right: 10px;
    &::after {
      ${ArrowTooltipCss};
      border-radius: 0 4px 0 20px;
      left: 100%;
    }
  }

  .tooltipInfo-icon {
    margin: unset;
    margin-right: 4.5px;
    ${breakpoints.phone} {
      display: none;
    }
    img {
      width: 25px;
      height: 21px;
    }
  }

  .tooltipInfo-button-text {
    display: flex;
    flex-direction: row;
    span {
      line-height: 13px;
      margin-right: 3px;
      color: ${Color.white};
      ${Font.sansSemiBold};
      font-size: 10px !important;
      text-align: center;
      padding: 0 !important;
      ${breakpoints.phone} {
        display: block !important;
      }
    }

    .lastDay {
      display: inline-block;
      ${Font.sansBold};
      font-size: 11px;
      ${breakpoints.phone} {
        font-size: 10px;
      }
    }
  }
  .tooltipInfo-button-close {
    background: none;
    border-radius: 4px;
    padding-top: 4px;
    width: 12px;
    height: 13px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    svg {
      width: 15px;
      height: 15px;
    }
  }

  &.hide {
    display: none;
  }
`;
