import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;

  ~ ul {
    display: flex;
    opacity: 0;
    height: 100vh;
    max-width: 300px;
    left: -100%;
    overflow: initial;
    transition: left 0.3s ease-in-out;
  }

  &:checked ~ ul {
    z-index: 2000000 !important;
    opacity: 1;
    position: fixed;
    pointer-events: initial;
    top: 0;
    left: 0;
    overflow-y: scroll;
    &.noBannerTop {
      /* max-height: calc(100vh - 112px); */
      height: 100vh;
    }
    ${breakpoints.phone} {
      /*  max-height: calc(100vh - 232px); */
      height: 100vh;
      &.noBannerTop {
      /* max-height: calc(100vh - 114px); */
        height: 100vh;
      }
    }
  }

  &.home {
    & ~ label {
      .tooltip-alert {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #39dff5;
        margin-top: -1px;
        margin-left: 15px;
      }
    }
    ${breakpoints.mobileFirst} {
      display: none;
      & ~ label {
        display: none;
      }
    }
  }
  &.sticky.home ~ label,
  &.sticky.home {
    display: block;
  }
`;
export const SubMenu = styled.ul`
  display: none;
  position: absolute;
  top: 57px;
  left: 0;
  overflow-y: auto;
  width: 300px;
  padding: 10px 0;
  background-color: ${Color.weatherGray};
  z-index: 2000000 !important;
  flex-direction: column;
  ${breakpoints.phone} {
    top: 53px;
  }

  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      background-color: ${ColorDark.darkGrey};
    }
  }

  &.home {
    top: 72px;
    ${breakpoints.phone} {
      top: 53px;
    }
  }
  &.sticky {
    top: 55px;
    ${breakpoints.phone} {
      top: 51px;
    }
  } 

  li {
    position: relative;
    border-bottom: 1px solid ${Color.tertiaryColor};
    width: 100%;
    &:hover {
      background-color: ${Color.tertiaryColor};
    }
    ${breakpoints.phone}  {
      &:hover {
        background-color: initial;
      }
    }
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        border-bottom: 1px solid ${ColorDark.greySeparator};
      }
    }
    &:first-child {
      border-bottom: none;
    }

    .name {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      .tooltip {
        min-height: 24px;
        min-width: 60px;
        max-width: min-content;
        background-color: #39dff5;
        color: ${Color.black000};
        text-align: center;
        border-radius: 3px;
        padding: 6px 8px;
        margin-left: 12px;
        position: relative;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        ${Font.sansRegular};
        font-size: 12px;
        text-transform: uppercase;
        ::after {
          content: "";
          position: absolute;
          border-width: 5px;
          border-style: solid;
          margin-top: -5px;
          top: 50%;
          right: 100%;
          border-color: transparent #39dff5 transparent transparent;
        }
      }
    }
    /* .float-link{
      display: none; /* BLOCK TEMPORAL 
      position: absolute;
      width: 250px;
      top: 9px;
      left: 299px;
      z-index: 110;
      background-color: ${Color.white};
      border-radius: 8px;
      padding: 1rem;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
      &::after{
        position: absolute;
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 0 4px;
        transform: rotate(45deg);
        background-color: ${Color.white};
        left: -9px;
        top: 10px;
        box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.1);
      }
       
      .linkSub {
        /* background-color: red; 
        margin: 0;
        padding: .75rem 1rem;
        border-radius: 4px;
        &:hover {
          background-color:${Color.tertiaryColor};
        }
      }
    } */
    /* .float-link_arrow {
      svg {
        transform: rotate(270deg);
        fill: ${Color.black};
        position: absolute;
        right: 10px;
        top: 20px;
        z-index: 103;
      }
    }
    .float-link_arrow:hover .float-link,
    .float-link:hover {
      display: block;
    } */

    details {
      summary {
        position: absolute;
        right: 21px;
        top: 20px;
        z-index: 4;
        ::-webkit-details-marker {
          display: none;
        }
        a {
          ${Font.sansRegular};
          color: ${Color.white};
          cursor: pointer;
          font-size: 14px;
          line-height: 16px;
          transition: 0.3s;
          text-decoration: none;
        }
      }
      > div {
        gap: 0;
      }
      &[open] {
        background: ${Color.white};
        overflow: hidden;
        padding: 1rem;
        summary svg {
          transform: rotate(-180deg);
        }
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            background: ${ColorDark.darkGrey};
            outline: 2.3rem solid ${ColorDark.darkGrey};
          }
        }
        .linkSub {
          margin: 0;
          padding: .75rem 1rem;
          border-radius: 4px;
          &:hover {
            background-color:${Color.tertiaryColor};
          }
        }
      }
    }
    &.item {
      span {
        margin-left: 0;
        .tooltip.tooltip-direction-right {
          position: inherit;
          display: flex;
          min-width: max-content !important;
          margin-top: 0;
          margin-left: 0.5rem;
          border-radius: 20px;
          box-shadow: none;
          font-size: 10px;
          &::after {
            display: none;
          }
        }
      }
      .linkFirst {
        ${Font.sansBold};
        color: ${Color.black000};
        cursor: pointer;
        font-size: 15px;
        line-height: 16px;
        transition: 0.3s;
        text-decoration: none;
        text-transform: uppercase;
        z-index: 3;
        position: relative;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        width: 100%;
        min-height:100%;
        padding: 0 1rem;
        > div {
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: auto;
            height: 16px !important;
          }
        }
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            color: ${ColorDark.textLight};
          }
        }
        &:hover {
          color: ${Color.mainColor};
          ${breakpoints.darkMode} {
            [data-theme="dark"] & {
              color: ${ColorDark.mainColor};
            }
          }
        }
      }
      &.sidebar-dropdown {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        details &:nth-child(2) {
          position: absolute;
          top: 0;
          background-color: red;
        }
        svg {
          width: 14px;
          ${breakpoints.darkMode} {
            [data-theme="dark"] & {
              fill: ${ColorDark.grey};
            }
          }
        }
      }
    }
  }

  .submenu-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        background-color: ${Color.darkGrey};
      }
    }
    .close {
      background: transparent;
      border: none;
      display: flex;
      justify-content: flex-start;
      padding: 0 7px;
      position: relative;
      align-items: center;
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 16px;
        width: 3px;
        background-color: ${Color.black000};
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            background-color: ${ColorDark.textLight};
          }
        }
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &:hover{
      background-color: initial;
    }
    &_btn {
      padding: 1rem;
      &:hover{
        background-color: initial;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Color.tertiaryColor};
  }
  ::-webkit-scrollbar-track {
    background: ${Color.weatherGray};
  }
`;

export const SubMenuChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  a.linkSub {
    ${Font.sansRegular};
    color: #000;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    transition: 0.3s;
    text-decoration: none;
    margin-top: 13px;
    margin-left: 13px;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
    &:hover {
      color: ${Color.mainColor};
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.mainColor};
        }
      }
    }
  }
`;

export const OverlayMenuOpen = styled.div`
  width: 100%;
  height: 100vh;
  display: none;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 30%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
`;
